import axios from "axios";

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null,
        passwordResetSuccess: false,
        passwordResetErrorMessage: null
    },
    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        passwordResetSuccess(state) {
            return state.passwordResetSuccess;
        },
        passwordResetErrorMessage(state) {
            return state.passwordResetErrorMessage;
        }
    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_PASSWORD_RESET_SUCCESS(state, value) {
            state.passwordResetSuccess = value;
        },
        SET_PASSWORD_RESET_ERROR_MESSAGE(state, value) {
            state.passwordResetErrorMessage = value;
        }
    },
    actions: {
        async login({ dispatch }, credentials) {
            await axios.get("/sanctum/csrf-cookie");
            await axios.post("/api/login", credentials);

            return dispatch("me");
        },
        async forgot({ dispatch }, email) {
            await axios.get("/sanctum/csrf-cookie");
            await axios.post("/api/forgot", email);
        },
        async reset({ dispatch, commit }, form) {
            await axios.get("/sanctum/csrf-cookie");
            await axios
                .post("/api/reset", form)
                .then(response => {
                    commit("SET_PASSWORD_RESET_SUCCESS", response.data.success);
                    if (response.data.success) {
                        return dispatch("login", {
                            email: form.email,
                            password: form.password
                        });
                    } else {
                        commit("SET_PASSWORD_RESET_ERROR_MESSAGE", [
                            response.data.message
                        ]);
                    }
                })
                .catch(err => {
                    console.log({
                        err,
                        "error status": err.response.status,
                        "error response": err.response.data
                    });
                    commit(
                        "SET_PASSWORD_RESET_ERROR_MESSAGE",
                        err.response.data.errors.password
                    );
                });
        },
        me({ commit }) {
            return axios
                .get("/api/user")
                .then(response => {
                    commit("SET_AUTHENTICATED", true);
                    commit("SET_USER", response.data);
                })
                .catch(() => {
                    commit("SET_AUTHENTICATED", false);
                    commit("SET_USER", null);
                });
        },
        async logout({ dispatch }) {
            await axios.post("/api/logout");

            return dispatch("me");
        }
    }
};
