import { createRouter, createWebHistory } from "vue-router";

import Login from "../views/Login.vue";
import Conferences from "../views/Conferences.vue";
import Sessions from "../views/Sessions.vue";
import Forgot from "../views/Forgot.vue";
import Reset from "../views/Reset.vue";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/conferences",
        name: "conferences",
        component: Conferences
    },
    {
        path: "/sessions",
        name: "sessions",
        component: Sessions
    },
    {
        path: "/forgot",
        name: "forgot",
        component: Forgot
    },
    {
        path: "/reset",
        name: "reset",
        component: Reset,
        props: route => ({ email: route.query.email, token: route.query.token })
    },
    {
        path: "/:notFound(.*)",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
