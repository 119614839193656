<template>
    <h1 class="text-3xl font-bold">Your Upcoming Conferences</h1>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="my-12 overflow-hidden bg-white shadow sm:rounded-md">
        <ul class="divide-y divide-gray-200">
            <template v-if="conferences.length">
                <li v-for="conference in conferences" :key="conference.uuid">
                    <a href="#" class="block hover:bg-gray-50">
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="flex items-center flex-1 min-w-0">
                                <div class="flex-shrink-0">
                                    <img
                                        class="w-12 h-12 rounded-full"
                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4"
                                >
                                    <div>
                                        <p
                                            class="text-sm font-medium text-indigo-600 truncate"
                                        >
                                            {{ conference.name }}
                                        </p>
                                        <p
                                            class="flex items-center mt-2 text-sm text-gray-500"
                                        >
                                            <span class="truncate">{{
                                                conference.description
                                            }}</span>
                                        </p>
                                    </div>
                                    <div class="hidden md:block">
                                        <div>
                                            <p class="text-sm text-gray-900">
                                                <time
                                                    datetime="{{ conference.starts }}"
                                                    >{{
                                                        conference.starts
                                                    }}</time
                                                >
                                                <span> - </span>
                                                <time
                                                    datetime="{{ conference.ends }}"
                                                    >{{ conference.ends }}</time
                                                >
                                            </p>

                                            <p
                                                class="flex items-center mt-2 text-sm text-gray-500"
                                            >
                                                <!-- Heroicon name: check-circle -->
                                                <svg
                                                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                                Completed phone screening
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- Heroicon name: chevron-right -->
                                <svg
                                    class="w-5 h-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </a>
                </li>
            </template>
            <li v-else>You are not registered for any conferences</li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            conferences: "conferences/conferences"
        })
    },
    methods: {
        ...mapActions({
            getConferences: "conferences/getConferences"
        })
    },

    mounted() {
        this.getConferences();
    }
};
</script>
