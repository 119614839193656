<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative overflow-hidden">
        <div class="relative pt-6 pb-16 sm:pb-24">
            <main class="px-4 mx-auto mt-16 max-w-7xl sm:mt-24">
                <div class="text-center">
                    <h1
                        class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
                    >
                        <span class="block xl:inline">404 </span>

                        <span class="block text-purple-600 xl:inline">
                            Page Not Found
                        </span>
                    </h1>

                    <div
                        v-if="!authenticated"
                        class="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8"
                    >
                        <div class="rounded-md shadow">
                            <router-link
                                to="login"
                                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                            >
                                Log in
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-else
                        class="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8"
                    >
                        <div class="rounded-md shadow">
                            <router-link
                                to="sessions"
                                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                            >
                                View your sessions
                            </router-link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        Logo
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user"
        })
    }
};
</script>
