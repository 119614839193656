import axios from "axios";

export default {
    namespaced: true,

    state: {
        conferences: []
    },
    getters: {
        conferences(state) {
            return state.conferences;
        }
    },
    mutations: {
        SET_CONFERENCES(state, conferences) {
            state.conferences = conferences;
        }
    },
    actions: {
        async getConferences({ commit }) {
            let response = await axios.get("/api/conferences");

            commit("SET_CONFERENCES", response.data.data);
        }
    }
};
