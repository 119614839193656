<template>
    <div class="flex items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div class="w-full max-w-md space-y-8">
            <div>
                <div class="w-auto h-12 mx-auto">
                    <Logo />
                </div>

                <h2
                    class="mt-6 text-3xl font-extrabold text-center text-gray-900"
                >
                    Forgot your password?
                </h2>
                <p class="mt-10">
                    No problem. Just let us know your email address and we will
                    email you a password reset link that will allow you to
                    choose a new one.
                </p>
            </div>
            <form
                v-if="!emailSent"
                class="mt-8 space-y-6"
                action="#"
                @submit.prevent="forgot"
            >
                <input type="hidden" name="remember" value="true" />
                <div class="-space-y-px rounded-md shadow-sm">
                    <div>
                        <label for="email-address" class="sr-only"
                            >Email address</label
                        >
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required
                            class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                            v-model="form.email"
                        />
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md group hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        <span
                            class="absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <!-- Heroicon name: lock-closed -->
                            <svg
                                class="w-5 h-5 text-purple-500 group-hover:text-purple-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                        Send reset email
                    </button>
                </div>
            </form>
            <div v-else>
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div class="p-4 rounded-md bg-green-50">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: check-circle -->
                            <svg
                                class="w-5 h-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                                Password reset sent successfully
                            </h3>
                            <div class="mt-2 text-sm text-green-700">
                                <p>
                                    Please check your email for a password reset
                                    link. From there you will be able to reset
                                    your password.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Logo from "../components/Logo.vue";

export default {
    data() {
        return {
            form: {
                email: ""
            },
            emailSent: false
        };
    },
    components: { Logo },
    methods: {
        ...mapActions({
            forgotAction: "auth/forgot"
        }),
        async forgot() {
            await this.forgotAction(this.form).then(response => {
                this.emailSent = true;
            });
        }
    }
};
</script>
