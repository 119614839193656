<template>
    <div v-if="authenticated">
        <h1 class="text-3xl font-bold">Your Upcoming Sessions</h1>
        <div class="my-12 overflow-hidden bg-white shadow sm:rounded-md">
            <ul class="divide-y divide-gray-200">
                <template v-if="sessions.length">
                    <li v-for="session in sessions" :key="session.id">
                        <div class="block hover:bg-gray-50">
                            <div class="flex items-center px-4 py-4 sm:px-6">
                                <div class="flex items-center flex-1 min-w-0">
                                    <div
                                        class="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4"
                                    >
                                        <div>
                                            <p
                                                class="text-xl font-medium text-indigo-600 truncate"
                                            >
                                                {{ session.name }}
                                            </p>
                                            <!-- <p
                                            class="flex items-center mt-2 text-sm text-gray-500"
                                        >
                                            <span class="truncate">{{
                                                session.description
                                            }}</span>
                                        </p> -->
                                        </div>
                                        <div class="hidden md:block">
                                            <div>
                                                <p
                                                    class="text-sm text-gray-900"
                                                >
                                                    Session available starting
                                                    <time
                                                        datetime="{{ new Date(session.start_date).toDateString() }}"
                                                        >{{
                                                            new Date(
                                                                session.start_date
                                                            ).toDateString()
                                                        }}</time
                                                    >
                                                </p>

                                                <p
                                                    v-if="
                                                        session.session_file_id
                                                    "
                                                    class="flex items-center mt-2 text-sm text-gray-500"
                                                >
                                                    <!-- Heroicon name: check-circle -->
                                                    <svg
                                                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                    Video file uploaded

                                                    <button
                                                        class="ml-10 text-red-400"
                                                        @click.prevent="
                                                            removeVideo(
                                                                session.code
                                                            )
                                                        "
                                                    >
                                                        Delete
                                                    </button>
                                                </p>
                                                <div v-else>
                                                    <div class="w-full">
                                                        <div
                                                            class="flex items-center mt-2 text-sm text-gray-500"
                                                        >
                                                            <svg
                                                                class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                                    clip-rule="evenodd"
                                                                />
                                                            </svg>
                                                            Video file not
                                                            uploaded:
                                                        </div>
                                                        <!-- Heroicon name: check-circle -->

                                                        <div>
                                                            <app-uploader
                                                                :sessionCode="
                                                                    session.code
                                                                "
                                                                @onprocessfile="
                                                                    storeFile
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div>

                                <svg
                                    class="w-5 h-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div> -->
                            </div>
                        </div>
                    </li>
                </template>
                <li v-else>You are not presenting in any sessions</li>
            </ul>
        </div>
    </div>
    <div v-else>
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
            Please log in to view your sessions.
        </h2>

        <div class="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8">
            <div class="rounded-md shadow">
                <router-link
                    to="login"
                    class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                >
                    Log in
                </router-link>
            </div>
        </div>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppUploader from "@/components/AppUploader.vue";
import axios from "axios";

export default {
    beforeRouteEnter() {},
    components: {
        AppUploader
    },
    computed: {
        ...mapGetters({
            sessions: "sessions/sessions",
            authenticated: "auth/authenticated"
        })
    },
    methods: {
        ...mapActions({
            getSessions: "sessions/getSessions",
            deleteVideoFile: "sessions/deleteVideoFile"
        }),
        async storeFile(file, sessionCode) {
            await axios
                .post("/api/files", {
                    name: file.filename,
                    size: file.fileSize,
                    path: file.serverId,
                    disk: "bb_s3",
                    sessionCode
                })
                .then(response => {
                    this.getSessions();
                });
        },
        async removeVideo(sessionCode) {
            if (window.confirm("Are you sure you want to delete this video?")) {
                this.deleteVideoFile(sessionCode);
                this.getSessions();
            }
        }
    },

    mounted() {
        this.getSessions();
    }
};
</script>
