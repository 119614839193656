import { createStore } from "vuex";
import auth from "./auth";
import conferences from "./conferences";
import sessions from "./sessions";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        conferences,
        sessions
    }
});
