<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="bg-white shadow">
        <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="relative flex justify-between h-16">
                <div
                    class="absolute inset-y-0 left-0 flex items-center sm:hidden"
                >
                    <!-- Mobile menu button -->
                    <button
                        class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        aria-expanded="false"
                    >
                        <span class="sr-only">Open main menu</span>
                        <!-- Icon when menu is closed. -->
                        <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          -->
                        <svg
                            class="block w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                        <!-- Icon when menu is open. -->
                        <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          -->
                        <svg
                            class="hidden w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div class="flex items-center justify-between w-full">
                    <div
                        class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start"
                    >
                        <div class="flex items-center flex-shrink-0">
                            <div class="block w-auto h-8 lg:hidden">
                                <Logo />
                            </div>
                            <div class="hidden w-auto h-8 lg:block">
                                <Logo />
                            </div>
                        </div>
                        <div
                            v-if="authenticated"
                            class="hidden sm:ml-6 sm:flex sm:space-x-8"
                        >
                            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
                            <!-- <router-link
                                to="conferences"
                                class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700"
                            >
                                Conferences
                            </router-link> -->
                            <router-link
                                to="sessions"
                                class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700"
                            >
                                Sessions
                            </router-link>
                        </div>
                    </div>

                    <div
                        v-if="authenticated"
                        class="hidden sm:flex sm:space-x-8"
                    >
                        <div
                            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent justify-self-end hover:border-gray-300 hover:text-gray-700"
                        >
                            {{ user.name }}
                        </div>
                        <a
                            href="#"
                            @click.prevent="logout"
                            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent justify-self-end hover:border-gray-300 hover:text-gray-700"
                        >
                            Sign Out
                        </a>
                    </div>
                    <div v-if="!authenticated">
                        <router-link
                            to="login"
                            href=""
                            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent justify-self-end hover:border-gray-300 hover:text-gray-700"
                        >
                            Sign In
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
        <div class="hidden sm:hidden">
            <div class="pt-2 pb-4 space-y-1">
                <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
                <a
                    href="#"
                    class="block py-2 pl-3 pr-4 text-base font-medium text-indigo-700 border-l-4 border-indigo-500 bg-indigo-50"
                    >Dashboard</a
                >
                <a
                    href="#"
                    class="block py-2 pl-3 pr-4 text-base font-medium text-gray-500 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                    >Events</a
                >
                <a
                    href="#"
                    class="block py-2 pl-3 pr-4 text-base font-medium text-gray-500 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                    >Sign Out</a
                >
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        Logo
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user"
        })
    },
    methods: {
        ...mapActions({
            logoutAction: "auth/logout"
        }),

        async logout() {
            await this.logoutAction();

            this.$router.replace({ name: "login" });
        }
    }
};
</script>
