<template>
    <Navbar />

    <div class="px-4 py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <router-view />
    </div>
</template>

<style src="./assets/styles/app.css" />

<script>
import Navbar from "@/components/Navbar.vue";

export default {
    components: {
        Navbar
    }
};
</script>
