<template>
    <div
        class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8"
    >
        <div class="w-full max-w-md space-y-8">
            <div>
                <div class="w-auto h-12 mx-auto">
                    <Logo />
                </div>

                <h2
                    v-if="!authenticated"
                    class="mt-6 text-3xl font-extrabold text-center text-gray-900"
                >
                    Sign in to your account
                </h2>
                <div v-else>
                    <h2
                        class="mt-6 text-3xl font-extrabold text-center text-gray-900"
                    >
                        You're already logged in!
                    </h2>

                    <div
                        class="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8"
                    >
                        <div class="rounded-md shadow">
                            <router-link
                                to="sessions"
                                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                            >
                                View your sessions
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <form
                v-if="!authenticated"
                class="mt-8 space-y-6"
                action="#"
                @submit.prevent="login"
            >
                <input type="hidden" name="remember" value="true" />
                <div class="-space-y-px rounded-md shadow-sm">
                    <div>
                        <label for="email-address" class="sr-only"
                            >Email address</label
                        >
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required
                            class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                            v-model="form.email"
                        />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autocomplete="current-password"
                            required
                            class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                            placeholder="Password"
                            v-model="form.password"
                        />
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <input
                            id="remember_me"
                            name="remember_me"
                            type="checkbox"
                            class="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
                        />
                        <label
                            for="remember_me"
                            class="block ml-2 text-sm text-gray-900"
                        >
                            Remember me
                        </label>
                    </div>

                    <div class="text-sm">
                        <router-link
                            to="forgot"
                            class="font-medium text-purple-600 hover:text-purple-500"
                        >
                            Forgot your password?
                        </router-link>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md group hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        <span
                            class="absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <!-- Heroicon name: lock-closed -->
                            <svg
                                class="w-5 h-5 text-purple-500 group-hover:text-purple-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Logo from "../components/Logo.vue";

export default {
    data() {
        return {
            form: {
                email: "",
                password: ""
            }
        };
    },
    components: { Logo },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user"
        })
    },
    methods: {
        ...mapActions({
            loginAction: "auth/login"
        }),
        async login() {
            await this.loginAction(this.form);

            this.$router.replace({ name: "sessions" });
        }
    }
};
</script>
