<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 121 148"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(1,0,0,1,-29.5291,-16.4955)">
      <g transform="matrix(1.82127,0,0,1.82127,-303.12,15.982)">
        <path
          d="M201.036,29.659C199.836,30.579 198.078,30.465 197.157,29.267C196.375,28.252 196.471,26.802 197.456,25.984C207.967,17.243 223.616,17.161 234.225,25.792C235.221,26.602 235.331,28.054 234.557,29.078C233.646,30.285 231.891,30.416 230.681,29.508C221.977,22.977 209.667,23.039 201.036,29.659Z"
          style="fill:rgb(93,32,94);fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(1.82127,0,0,1.82127,-303.12,15.982)">
        <path
          d="M194.002,22.889C192.762,23.901 190.893,23.747 189.956,22.448C189.196,21.396 189.315,19.937 190.296,19.086C197.31,12.996 206.278,9.64 215.791,9.592L216.001,9.591C225.433,9.591 234.354,12.848 241.383,18.819C242.376,19.662 242.51,21.125 241.756,22.187C240.83,23.494 238.963,23.666 237.712,22.666C231.608,17.789 224.012,15.138 216.001,15.138L215.814,15.138C207.734,15.18 200.098,17.912 194.002,22.889Z"
          style="fill:rgb(124,40,124);fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(1.82127,0,0,1.82127,-303.12,15.982)">
        <path
          d="M186.999,16.166C185.851,17.15 184.092,17.031 183.169,15.833C182.388,14.819 182.487,13.373 183.444,12.523C201.667,-3.668 229.831,-3.815 248.236,12.187C249.205,13.03 249.317,14.478 248.543,15.502C247.632,16.708 245.874,16.844 244.715,15.872C228.178,2.012 203.385,2.138 186.999,16.166Z"
          style="fill:rgb(145,39,143);fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(1.82127,0,0,1.82127,-303.12,15.982)">
        <path
          d="M226.1,53.657C224.899,53.657 223.925,52.74 223.925,51.609C223.925,50.477 224.899,49.56 226.1,49.56C227.302,49.56 228.275,50.477 228.275,51.609C228.275,52.74 227.302,53.657 226.1,53.657ZM235.888,49.562C235.441,39.558 226.694,31.576 215.966,31.576C205.237,31.576 196.49,39.558 196.043,49.562L196.022,49.562C196.022,57.539 200.089,59.348 200.089,61.883C200.089,77.322 199.346,81 199.346,81L216.158,81L216.158,75.18L228.791,75.18C230.083,75.194 229.163,69.231 228.863,63.462L235.745,63.424C237.14,63.414 236.028,55.717 235.909,49.562L235.888,49.562Z"
          style="fill:rgb(249,194,43);fill-rule:nonzero;"
        />
      </g>
    </g>
  </svg>
</template>
