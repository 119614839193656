import axios from "axios";

export default {
    namespaced: true,

    state: {
        sessions: []
    },
    getters: {
        sessions(state) {
            return state.sessions;
        }
    },
    mutations: {
        SET_SESSIONS(state, sessions) {
            state.sessions = sessions;
        }
    },
    actions: {
        async getSessions({ commit }) {
            let response = await axios.get("/api/sessions");

            commit("SET_SESSIONS", response.data.data);
        },

        async deleteVideoFile({ commit }, sessionCode) {
            await axios.post(`/api/sessions/remove-video/${sessionCode}`);
        }
    }
};
