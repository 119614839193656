<template>
    <div
        class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8"
    >
        <div class="w-full max-w-md space-y-8">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="p-4 rounded-md bg-green-50" v-if="passwordResetSuccess">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <!-- Heroicon name: check-circle -->
                        <svg
                            class="w-5 h-5 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">
                            Password Reset Successful
                        </h3>
                        <div class="mt-2 text-sm text-green-700">
                            <p>
                                You may now view your sessions.
                            </p>
                        </div>
                        <div class="mt-4">
                            <div class="-mx-2 -my-1.5 flex">
                                <router-link
                                    to="sessions"
                                    class="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                                >
                                    View sessions
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="w-auto h-12 mx-auto">
                    <Logo />
                </div>

                <h2
                    class="mt-6 text-3xl font-extrabold text-center text-gray-900"
                >
                    Reset your password
                </h2>
                <!-- This example requires Tailwind CSS v2.0+ -->

                <div
                    class="p-4 mt-10 rounded-md bg-red-50"
                    v-if="passwordResetErrorMessage && !passwordResetSuccess"
                >
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: x-circle -->
                            <svg
                                class="w-5 h-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">
                                There were errors with your password reset
                            </h3>
                            <div class="mt-2 text-sm text-red-700">
                                <ul class="pl-5 space-y-1 list-disc">
                                    <li
                                        v-for="(error,
                                        index) in passwordResetErrorMessage"
                                        :key="index"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-4">
                                <div class="-mx-2 -my-1.5 flex">
                                    <router-link
                                        to="forgot"
                                        class="ml-3 bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                    >
                                        Cancel Reset
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="mt-8 space-y-6" action="#" @submit.prevent="reset">
                    <input type="hidden" name="remember" value="true" />
                    <div class="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label for="email-address" class="sr-only"
                                >Email address</label
                            >
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autocomplete="email"
                                required
                                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                                v-model="form.email"
                            />
                        </div>
                        <div>
                            <label for="password" class="sr-only"
                                >New Password</label
                            >
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autocomplete="current-password"
                                required
                                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                placeholder="New Password"
                                v-model="form.password"
                            />
                        </div>
                        <div>
                            <label for="confirm" class="sr-only"
                                >Confirm Password</label
                            >
                            <input
                                id="confirm"
                                name="confirm"
                                type="password"
                                autocomplete="current-confirm"
                                required
                                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                placeholder="Confirm New Password"
                                v-model="form.password_confirmation"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md group hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            <span
                                class="absolute inset-y-0 left-0 flex items-center pl-3"
                            >
                                <!-- Heroicon name: lock-closed -->
                                <svg
                                    class="w-5 h-5 text-purple-500 group-hover:text-purple-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </span>
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Logo from "../components/Logo.vue";

export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
                password_confirmation: "",
                token: ""
            }
        };
    },
    props: {
        email: {
            type: String,
            default: null
        },
        token: {
            type: String,
            default: null
        }
    },
    components: { Logo },
    computed: {
        ...mapGetters({
            passwordResetSuccess: "auth/passwordResetSuccess",
            passwordResetErrorMessage: "auth/passwordResetErrorMessage"
        })
    },
    mounted() {
        this.form.email = this.email;
        this.form.token = this.token;
    },
    methods: {
        ...mapActions({
            resetAction: "auth/reset",
            loginAction: "auth/login"
        }),
        async reset() {
            await this.resetAction(this.form);
        },
        async login() {
            await this.loginAction(this.form);

            this.$router.replace({ name: "sessions" });
        }
    }
};
</script>
